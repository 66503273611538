










import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            activeView: ''
        };
    },
    methods: {
        toggleView(): void {
            // this.$store.commit('toggleView'); << directly commiting mutation ??
            this.$store.dispatch('toggleView');
            console.log('<VIEW SWITCH> active view: ', this.$store.state.view);
        }
    },
    computed: {
        selectedView(): string {
            const selectedView = this.$store.getters.getSelectedView;
            console.log('<TABLE> getSelectedView: ', selectedView);
            return selectedView;
        }
    }
});
