

































import Vue from 'vue';
// import { mapState } from 'vuex';

export default Vue.extend({
    props: {
        covData: []
    },
    data() {
        return {
            name: 'Table',
            fields: [
                {
                    key: 'country',
                    sortable: false
                },
                {
                    key: 'confirmed',
                    sortable: true
                },
                {
                    key: 'deaths',
                    sortable: true
                },
                {
                    key: 'recovered',
                    sortable: true
                }
            ]
        };
    },
    methods: {},
    computed: {
        isBusy(): boolean {
            const loadingState = this.$store.getters.getLoadingStatus;
            console.log('<TABLE> getLoadingState: ', loadingState);
            return loadingState;
        },
        items(): Array<Record<string, any>> {
            const data = this.$store.getters.getCountriesData;
            return data;
        },
        fetchTime(): string {
            const time = this.$store.getters.getFetchTime;
            return time;
        }
    }
});
