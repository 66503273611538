



















import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            name: 'Search',
            searchValue: '',
            items: [{}]
        };
    },
    methods: {
        search() {
            this.items = [
                ...this.items,
                { countryName: 'Austria', activeCases: 50000, deaths: 1000, recoveries: 40000 }
            ];
        }
    },
    computed: {
        // searchState() {
        //     return this.searchValue
        // },
    }
});
