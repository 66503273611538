















































import Vue from 'vue';
import Search from './components/Search.vue';
import ViewSwitch from './components/viewSwitch.vue';
import Table from './components/Table.vue';
import Chart from './components/Chart.vue';

export default Vue.extend({
    name: 'App',
    components: {
        Search,
        ViewSwitch,
        Table,
        Chart
    },
    data() {
        return {};
    },
    computed: {
        selectedView(): string {
            const selectedView = this.$store.getters.getSelectedView;
            console.log('<TABLE> getSelectedView: ', selectedView);
            return selectedView;
        }
        // ...mapState(['data'])
        // summary() {
        //     return this.$store.state.data;
        // }
    },
    methods: {},
    mounted() {
        this.$store.dispatch('getSummaryData');
    }
});
